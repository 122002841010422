import React, { ReactElement } from 'react';
import Page from '@src/components/Page';
import Stripe from '@src/components/Stripe';
import Screenshot from '@src/components/Screenshot';

const HowPage = (): ReactElement => {
  return (
    <Page title="How to Use The Extension">
      <div>
        <Stripe>
          <div className="container">
            <h2>
              Mod Queue
            </h2>
            <p className="text-center">
              The number of items in the mod queue appears on the app icon when there are items in the mod queue.
            </p>
            <Screenshot src="/images/pill.png" alt=""/>
          </div>
        </Stripe>

        <Stripe dark>
          <div className="container">
            <h2>
              Warnings
            </h2>
            <p className="text-center">
              Every comment in the subreddit will have a warn button.
            </p>
            <Screenshot src="/images/warn-btn.gif" alt=""/>

            <p className="text-center">
              Clicking the button opens a popup with the list of subreddit rules.
            </p>
            <Screenshot src="/images/rules.png" alt=""/>

            <p className="text-center">
              Click on the broken rule which opens up the message editor.
            </p>
            <Screenshot src="/images/warning.png" alt=""/>

            <p className="text-center">
              Finally click the "Send Warning" button to reply to the user with the message.
              The reply will be sent by SciNutritionBot and a user note will be created that records
              the warning.
            </p>
          </div>
        </Stripe>

        <Stripe>
          <div className="container">
            <h2>
              Tools
            </h2>
            <p className="text-center">
              Use tools to view and sort recent posts and comments.
            </p>
            <Screenshot src="/images/tools.png" alt=""/>
            <Screenshot src="/images/tools-posts.png" alt=""/>
          </div>
        </Stripe>

        <Stripe dark>
          <div className="container">
            <h2>
              Settings
            </h2>

            <p className="text-center">
              Settings let you control how the extension operates.
            </p>
            <Screenshot src="/images/settings.png" alt=""/>
            <Screenshot src="/images/settings-reddit.png" alt=""/>
            <Screenshot src="/images/settings-rules.png" alt=""/>
          </div>
        </Stripe>
      </div>
    </Page>
  );
};

export default HowPage;
