import styled from 'styled-components';

export const Container = styled.header`
  // position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: #2d2d2d;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  border-bottom: 1px solid #000;
`;

export const Logo = styled.img`
  height: 50px;
`;
