import React, { ReactElement } from 'react';
import { Container, Logo } from './styles';

const Header = (): ReactElement => {
  return (
    <Container className="p-2">
      <a href="/">
        <Logo src="images/header-logo.png" className="logo" alt=""/>
      </a>
    </Container>
  );
};

export default Header;
