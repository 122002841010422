import styled from 'styled-components';

const Screenshot = styled.img`
  max-width: 100%;
  display: block;
  margin: 1.5rem auto;
  border: 1px solid #4e4e4e;
`;

export default Screenshot;
