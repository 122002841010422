import styled from 'styled-components';

export const Container = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  background-color: #222222;
  box-shadow: 0 0 12px rgb(0, 0, 0, 0.25);

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  img {
    max-width: 100%;
  }
`;
