import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import Page from '@src/components/Page';
import Column from '@src/components/Column';

const InstallPage = (): ReactElement => {
  return (
    <Page title="How to Install">
      <Column>
        <div className="pt-2">
          <div className="text-center mb-3">
            <a href="/chrome.zip" className="btn btn-lg btn-download btn-redderoo mb-2">
              Download <i className="fa-solid fa-download ms-1" />
            </a>
          </div>
          <h2 className="text-center mb-5">
            How to Install
          </h2>

          <ol>
            <li className="mb-5">
              <h5 className="mb-3">
                Enable developer mode in Chrome if you haven&apos;t already.
              </h5>
              <p>
                Follow <Link to="/developer-mode">the instructions to enable developer mode</Link> before following
                the rest of these instructions.
              </p>
            </li>

            <li className="mb-5">
              <h5 className="mb-3">Download the chrome.zip file by clicking the button at the top of the page.</h5>
              <p>
                Unzip the file to a directory that won't be deleted or moved. For example your home
                directory. <i className="text-muted">c:\user\[username]</i> for windows
                and <i className="text-muted">/Users/[username]</i> for Mac. Once finished the
                directory <i className="text-muted">c:\user\[username]\redderoo</i> or
                <i className="text-muted">/Users/[username]/redderoo</i> should have been created.
              </p>
            </li>

            <li className="mb-5">
              <h5 className="mb-3">Goto Chrome settings using three dots on the top right corner.</h5>
              <img src="/images/1-2.webp" alt=""/>
            </li>

            <li className="mb-5">
              <h5 className="mb-3">Then select extensions.</h5>
              <img src="/images/2-3.webp" alt=""/>
            </li>

            <li className="mb-5">
              <h5 className="mb-3">Click on load unpacked and select your unzip folder.</h5>
              <img src="/images/4-3.webp" className="mb-2" alt=""/>
              <p>
                Note: You need to select the <i className="text-muted">c:\user\[username]\redderoo</i> or
                <i className="text-muted">/Users/[username]/redderoo</i> folder. The folder itself, not
                any of the files inside the folder.
              </p>
            </li>

            <li className="mb-5">
              <h5 className="mb-3">Authorize the extension</h5>
              <img src="/images/icon.png" className="mb-3" alt=""/>
              <p>
                The extension should be loaded and a red icon will appear in your browser toolbar.
                Right click the icon and select "Login". You will be redirected to reddit in order to grant
                permissions to the extension. Click "Allow" to continue.
              </p>
              <img src="/images/oauth.png" className="mb-2" alt=""/>
              <p>
                Installation is now complete.
              </p>
            </li>
          </ol>
        </div>
      </Column>
    </Page>
  );
};

export default InstallPage;
