import styled from 'styled-components';

interface StripeProps {
  dark?: boolean;
}

const Stripe = styled.div<StripeProps>`
  padding: 90px 0;
  background-color: ${p => p.dark ? '#252526' : 'transparent'};

  .container {
    max-width: 1000px;
  }

  h2 {
    text-align: center;
    margin-bottom: 2rem;
  }
`;

export default Stripe;
