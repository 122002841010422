import { createGlobalStyle } from 'styled-components';
import { darken } from 'polished';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html,
  body {
    min-height: 100vh;
  }

  body {
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    color: #FFF;
    background-color: #1A1A1B;
    height: 100%;
  }

  .btn-redderoo {
    color: #FFF;
    background-color: ${p => p.theme.colors.primary};
    border-color: ${p => p.theme.colors.primary};

    &:hover,
    &:focus,
    &:active {
      background-color: ${p => darken(0.1, p.theme.colors.primary)} !important;
      border-color: ${p => p.theme.colors.primary} !important;
    }
  }

  .btn-download {
    min-width: 300px;
  }
`;

export default GlobalStyle;
