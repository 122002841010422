import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;

  .logo-browser {
    width: 44px;
    height: 44px;
  }
`;

const zoom = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(100%);
  }
`;

export const Logo = styled.img`
  width: 128px;
  height: 128px;
  animation: ${zoom} 200ms linear;
`;
