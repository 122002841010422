import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

export interface Props {
  title: string;
  children: React.ReactNode;
}

const Page = ({ title, children }: Props): ReactElement => {
  return (
    <>
      <Helmet>
        <title>
          {title === 'Redderoo' ? title : `${title} - Redderoo`}
        </title>
      </Helmet>
      {children}
    </>
  );
};

export default Page;
