import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import GlobalStyle from './theme/global';
import Header from './components/Header';
import HomePage from './pages/Home';
import InstallPage from './pages/Install';
import DeveloperModePage from './pages/DeveloperMode';
import HowPage from './pages/How';
import ContactPage from './pages/Contact';

const App = () => {
  return (
    <>
      <GlobalStyle />
      <Router>
        <div>
          <Header />

          <Switch>
            <Route path="/install">
              <InstallPage />
            </Route>
            <Route path="/developer-mode">
              <DeveloperModePage />
            </Route>
            <Route path="/how">
              <HowPage />
            </Route>
            <Route path="/contact">
              <ContactPage />
            </Route>
            <Route path="/">
              <HomePage />
            </Route>
          </Switch>
        </div>
      </Router>
    </>
  );
}

export default App;
