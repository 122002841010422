import React, { ReactElement } from 'react';
import Page from '@src/components/Page';
import Column from '@src/components/Column';

const DeveloperModePage = (): ReactElement => {
  return (
    <Page title="Enable Developer Mode">
      <Column>
        <div className="pt-2">
          <h2 id="developer-mode" className="text-center">
            How to Enable Developer Mode
          </h2>
          <p className="text-center mb-1">
            Only follow these instructions if developer mode hasn't already been enabled.
          </p>
          <p className="text-center mb-5">
            Note: This step is necessary because the extension is private and therefore not being
            distributed through the Chrome extension store.
          </p>

          <ol>
            <li className="mb-5">
              <h5 className="mb-3">Goto Chrome settings using three dots on the top right corner.</h5>
              <img src="/images/1-2.webp" alt=""/>
            </li>

            <li className="mb-5">
              <h5 className="mb-3">Then select extensions.</h5>
              <img src="/images/2-3.webp" alt=""/>
            </li>

            <li className="mb-5">
              <h5 className="mb-3">Now, enable developer mode.</h5>
              <img src="/images/3-2.webp" alt=""/>
            </li>
          </ol>
        </div>
      </Column>
    </Page>
  );
};

export default DeveloperModePage;
