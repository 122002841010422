import React, { ReactElement } from 'react';
import Page from '@src/components/Page';
import Column from '@src/components/Column';

const ContactPage = (): ReactElement => {
  return (
    <Page title="Contact">
      <Column>
        <div style={{ width: 500, margin: '0 auto 30px auto' }}>
          <div className="mb-2">
            <label className="form-label" htmlFor="input-name">Name</label>
            <input
              id="input-name"
              className="form-control"
            />
          </div>

          <div className="mb-2">
            <label className="form-label" htmlFor="input-email">Email</label>
            <input
              id="input-email"
              className="form-control"
            />
          </div>

          <div className="mb-3">
            <label className="form-label" htmlFor="input-message">Message</label>
            <textarea
              id="input-message"
              className="form-control"
            />
          </div>

          <div className="mb-4">
            <button className="btn btn-primary">
              Submit
            </button>
          </div>
        </div>
      </Column>
    </Page>
  );
};

export default ContactPage;
