import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import Page from '@src/components/Page';
import Stripe from '@src/components/Stripe';
import Screenshot from '@src/components/Screenshot';
import packageJson from '@src/parentPackage.json';
import { Container, Logo } from './styles';

const HomePage = (): ReactElement => {
  return (
    <Page title="Redderoo">
      <Container>
        <Stripe className="text-center">
          <Logo src="images/icon-128.png" className="logo-icon d-block mb-3 m-auto" alt=""/>
          <h1 className="mb-3">
            {packageJson.description}
          </h1>
          <a href="/chrome.zip" className="btn btn-lg btn-download btn-redderoo mb-3">
            Download <i className="fa-solid fa-download ms-1" />
          </a>

          <div className="mb-2">
            <Link to="/install" className="link-instructions">
              Installation Instructions
            </Link>
            &nbsp;&middot;&nbsp;
            <Link to="/how" className="link-instructions">
              How to Use
            </Link>
          </div>
          <small className="d-block mb-3">
            Current version {packageJson.version} <span id="site-your-version"></span>
          </small>
          <img
            src="/images/chrome-icon.png"
            className="logo-browser d-block m-auto"
            alt="Available for Chrome"
          />
        </Stripe>

        <Stripe dark>
          <h2>
            Send Warnings
          </h2>
          <Screenshot src="/images/warn-btn.gif" alt=""/>

          <h2>
            View New Comments
          </h2>
          <Screenshot src="/images/tools.png" alt=""/>
        </Stripe>

      </Container>
    </Page>
  );
};

export default HomePage;
