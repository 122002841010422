import React, { ReactElement } from 'react';
import { Container } from './styles';

export interface Props {
  children: React.ReactNode;
}

const Column = ({ children }: Props): ReactElement => {
  return (
    <Container className="pt-4">
      {children}
    </Container>
  );
};

export default Column;
